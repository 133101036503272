@import '@/components/mixins.scss';

:global(div.ant-form-item-label) {
    height: 30px;
}

:global(.ant-form-item) {
    margin-bottom: 0 !important;
}

:global(.ant-collapse-content-box) {
    padding: 0 !important;
}